/**
 * Add the fragment of the given accordion element to the current URL for easy
 * copy and pasting.
 */
const updateLocationHashForAccordion = element => {
    if (!element.open) return;
    const id = element.id;
    if (!id) return;

    const hash = `#${id}`;
    window.history.replaceState({}, document.title, hash);
}

/**
 * Open the accordion element with an ID that matches the given fragment string.
 */
const openAccordionElementByHash = hash => {
    if (!hash) return;
    const accordionElementMatchingHash = accordionElements.find(
        el => `#${el.id}` === hash
    );
    if (!accordionElementMatchingHash) return;
    accordionElementMatchingHash.open = true;
}

// Whenever an accordion element is toggled, set the current URL fragment to its ID.
const accordionElements = Array.from(document.querySelectorAll('.accordion-element'));
accordionElements.forEach(element => element.addEventListener(
    'toggle',
    e => updateLocationHashForAccordion(element)
));

// Check for anchor links once on page load
openAccordionElementByHash(window.location.hash);

// When a link to an accordion on the same page is clicked, open that accordion
const accordionLinksOnPage = Array.from(document.querySelectorAll('a'))
    .filter(a => a.pathname === location.pathname &&
        a.protocol === location.protocol &&
        a.host === location.host &&
        a.search === location.search &&
        accordionElements.find(el => `#${el.id}` === a.hash)
    );
accordionLinksOnPage.forEach(a => a.addEventListener(
    'click',
    e => openAccordionElementByHash(a.hash),
));
