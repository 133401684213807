const breadcrumbSelects = Array.from(document.querySelectorAll('select[name=breadcrumbs-leaf]'));

const resizeSelectToCurrentOption = select => {
    const dummyOption = document.createElement('option');
    dummyOption.textContent = select.selectedOptions[0].textContent;
    const dummySelect = document.createElement('select');
    dummySelect.className = select.className;
    dummySelect.appendChild(dummyOption);
    dummySelect.style = 'width: min-content;';

    select.after(dummySelect);
    select.style.width = `${dummySelect.offsetWidth}px`;
    dummySelect.remove();
}

breadcrumbSelects.forEach(select => {
    resizeSelectToCurrentOption(select);

    select.addEventListener('change', e => {
        resizeSelectToCurrentOption(select);
        const url = e.target.value;
        window.location = url;
    });
});
