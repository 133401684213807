const personDialogOpenButtons = document.querySelectorAll('.person__dialog-open-button');
const personDialogCloseButtons = document.querySelectorAll('.person__dialog-close-button');

personDialogOpenButtons.forEach(button => button.addEventListener('click', e => {
    const dialog = button.closest('.person').querySelector('.person__dialog');
    dialog.showModal();
}));

personDialogCloseButtons.forEach(button => button.addEventListener('click', e => {
    const dialog = button.closest('.person__dialog');
    dialog.close();
}));
