const toggler = document.getElementById('site-navigation-button');
const navigationContent = document.getElementById('site-navigation-content');

const togglerActiveClassName = 'navbar__toggler--active';
const navigationContentActiveClassName = 'navigation__content--active';

if (toggler) {
    toggler.addEventListener('click', e => {
        const isActive = toggler.getAttribute('aria-expanded') === 'true';
        if (isActive) {
            navigationContent.classList.remove(navigationContentActiveClassName);
            toggler.classList.remove(togglerActiveClassName);
            toggler.setAttribute('aria-expanded', 'false');
        } else {
            navigationContent.classList.add(navigationContentActiveClassName);
            toggler.classList.add(togglerActiveClassName);
            toggler.setAttribute('aria-expanded', 'true');
        }
    });
}


const menu = document.querySelector('.menu');
const menuItemsWithFlyout = Array.from(menu.querySelectorAll('.menu__item'))
                            .filter(item => item.querySelector('.flyout') !== null);
const menuItemActiveClass = 'menu__item--hover';

menuItemsWithFlyout.forEach(item => {
    const link = item.querySelector('.menu__link');

    // show flyout on click if it isn't open already (mostly for touch devices)
    link.addEventListener('click', e => {
        // for fine pointers with hover states (non-touch devices), let the click go through
        if (window.matchMedia('(pointer: fine)').matches) return;

        const isActive = item.classList.contains(menuItemActiveClass);
        if (isActive) {
            // if the item is not a link of its own, allow the user to close the menu again
            if (link.nodeName !== 'A') {
                item.classList.remove(menuItemActiveClass);
            }
            // let the click go through
        } else {
            // activate the currently targeted item
            item.classList.add(menuItemActiveClass);
            // prevent the navigation
            e.preventDefault();
        }
    });
});
